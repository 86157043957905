<template>
    <div class="investment-portfolio">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "InvestmentPortfolio",
    }
</script>

<style lang="scss">
</style>